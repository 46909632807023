<template>
  <div>
    <v-btn style="margin: 10px;" @click="reenviaIntegracaoEmMassa" color="primary">
      {{ $t('label.reprocessar_relatorio') }}
    </v-btn>
    <v-btn @click="exportarConteudo"
            color="primary"
            :disabled="podeExportar"
    >{{ $t('label.exportar') }}
    </v-btn>
    <v-card style="width: 100%;">
      <v-data-table
        class="tabelaIntegracaoCancelamentoZver"
        :headers="headers"
        :items="integracoes"
        v-model="selecionados"
        show-select
        item-key="id"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">

        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom v-if="podeReenviarIntegracao && integracaoComErro(item)">
            <template v-slot:activator="{ on }">
              <v-btn @click.native="modalConfirmacaoReenviar(item)" v-on="on" text icon>
                <v-icon>replay</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.reenviar') }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.status="{ item }">
          <div style="white-space: nowrap">
            {{ $tc(`status_integracao.${item.status}`, 1) }}
          </div>
        </template>

        <template nowrap v-slot:item.vlrPagamento="{ item }">
          <div style="white-space: nowrap">
            {{ getMoney(item.vlrPagamento) }}
          </div>
        </template>

        <template nowrap v-slot:item.vlrIntegracao="{ item }">
          <div style="white-space: nowrap">
            {{ getMoney(item.vlrIntegracao) }}
          </div>
        </template>

        <template nowrap v-slot:item.rateio="{ item }">
          <v-btn icon @click="abrirRateio(item)">
            <v-icon>list_alt</v-icon>
          </v-btn>
        </template>

        <template nowrap v-slot:item.justificativa="{ item }">
          <div style="white-space: nowrap">
            <v-tooltip left>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip }" icon text>
                  <v-icon>assignment</v-icon>
                </v-btn>
              </template>
              <span>{{ item.justificativa }}</span>
            </v-tooltip>
          </div>
        </template>

        <template nowrap v-slot:item.respostaIntegracao="{ item }">
          <div style="white-space: nowrap">
            <v-tooltip left v-if="integracaoComErroOuPendente(item)">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip }" icon text>
                  <v-icon>info</v-icon>
                </v-btn>
              </template>
              <span>{{ formataMensagemDeResposta(item.respostaIntegracao) }}</span>
            </v-tooltip>
          </div>
        </template>

      </v-data-table>
    </v-card>
    <confirm
      ref="confirmDialog"
      :persistent="true"
    />
    <confirm
      ref="modalConfirmacaoReenvio"
      :message="$t('message.reenviar_integracao')"
      @agree="reenviaIntegracao"
      @disagree="$refs.modalConfirmacaoReenvio.close()">
    </confirm>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { countRelatorioCancelamentoZver } from '@/common/resources/quantidade-registro-relatorio-suzano';
import {
  buscaIntegracoesCancelamentoZver,
  reenviarIntegracaoCancelamentoZverEmMassa,
} from '@/common/resources/relatorios-integracao';
import { getMoney, removeEmptyProperties } from '../../../../produto/common/functions/helpers';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import exportacao from '../../../../produto/common/functions/exportacao';

export default {
  name: 'RelatorioIntegracaoCancelamentoZverTabela',
  data() {
    return {
      resource: this.$api.relatoriosIntegracao(this.$resource),
      integracoes: [],
      selecionados: [],
      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      filtros: null,
      integracaoReenvio: null,
      pagamentoIntegracao: null,
    };
  },
  components: {
    Confirm,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        if (this.filtros === null) {
          return;
        }
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    headers() {
      const headers = [];
      headers.push({
        text: '', width: '2%', value: 'acoes', sortable: false,
      });
      headers.push({
        text: this.$tc('label.status', 1), width: '10%', value: 'status', sortable: false,
      });
      headers.push({
        text: this.$tc('label.cod_acao', 1), width: '10%', value: 'codAcao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.cod_pagamento', 1), width: '10%', value: 'codPagamento', sortable: false,
      });
      headers.push({
        text: this.$tc('label.sequencial', 1), width: '6%', value: 'sequencial', sortable: false,
      });
      headers.push({
        text: this.$tc('label.data_integracao', 1), width: '6%', value: 'dataRequisicao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.num_ov', 1), width: '6%', value: 'numOv', sortable: false,
      });
      headers.push({
        text: this.$tc('label.num_fat_envio', 1), width: '6%', value: 'numFatEnvio', sortable: false,
      });
      headers.push({
        text: this.$tc('label.num_fat_retorno', 1), width: '6%', value: 'numFatRetorno', sortable: false,
      });
      headers.push({
        text: this.$tc('label.tipo_acao', 1), width: '10%', value: 'tipoAcao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.cod_cliente', 1), width: '6%', value: 'cliente', sortable: false,
      });
      headers.push({
        text: this.$tc('label.cliente', 1), width: '6%', value: 'nomCliente', sortable: false,
      });
      headers.push({
        text: this.$tc('label.valor_pagamento', 1), width: '6%', value: 'vlrPagamento', sortable: false,
      });
      headers.push({
        text: this.$tc('label.justificativa', 1), width: '6%', value: 'justificativa', sortable: false,
      });
      headers.push({
        text: this.$tc('label.doc_type', 1), width: '10%', value: 'docType', sortable: false,
      });
      headers.push({
        text: this.$tc('label.erro', 2), width: '6%', value: 'respostaIntegracao', sortable: false,
      });
      return headers;
    },
    podeReenviarIntegracao() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'LOG_INT_REENVIO').length > 0;
    },
    podeExportar() {
      return ((this.integracoes == null) || (this.integracoes.length <= 0));
    },
  },
  methods: {
    getMoney,
    abrirRateio(item) {
      this.pagamentoIntegracao = item;
      setTimeout(() => {
        this.$refs.pagamentoItensTabela.open();
      }, 5E2);
    },
    itensFechados() {
      this.pagamentoIntegracao = null;
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    filtrar(filtrosAplicados = null) {
      this.selecionados = [];

      if (filtrosAplicados) {
        this.filtros = filtrosAplicados;
        this.pagination.page = 1;
      }

      const parametros = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      buscaIntegracoesCancelamentoZver(parametros, this.$resource).then((res) => {
        this.totalPage = res.data.quantidadeRegistrosPagina;
        this.integracoes = res.data.resposta;
      });
    },
    formataMensagemDeResposta(retornoIntegracao) {
      let mensagem;
      try {
        mensagem = JSON.parse(retornoIntegracao || '{}');
      } catch (e) {
        mensagem = retornoIntegracao;
      }
      return mensagem;
    },
    integracaoComErroOuPendente(integracao) {
      return integracao && integracao.respostaIntegracao
        && ['ERRO', 'PENDENTE_INTEGRACAO'].includes(integracao.status);
    },
    integracaoComErro(integracao) {
      return integracao && integracao.status === 'ERRO';
    },
    modalConfirmacaoReenviar(integracao) {
      this.integracaoReenvio = integracao;
      this.$refs.modalConfirmacaoReenvio.open();
    },
    reenviaIntegracao() {
      if (!this.integracaoReenvio) {
        return;
      }

      const param = {
        idPagamentoIntegracao: this.integracaoReenvio.id,
      };

      this.resource.reenviarIntegracaoCancelamentoZver(param, param)
        .then(() => {
          this.filtrar();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    reenviaIntegracaoEmMassa() {
      if (!this.selecionados || this.selecionados.length <= 0) {
        this.$toast(this.$tc('label.selecione_item', 2));
        return;
      }

      const content = this.$t('message.deseja_reprocessar_integracao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              if (!this.selecionados || this.selecionados.length <= 0) {
                this.$toast(this.$tc('label.selecione_item', 2));
                return;
              }

              const ids = [];

              this.selecionados.forEach((selecionado) => {
                ids.push(selecionado.id);
              });

              const parametros = { ids };

              reenviarIntegracaoCancelamentoZverEmMassa(parametros, this.$resource, {})
                .then(() => {
                  this.integracoes = [];
                  this.selecionados = [];
                  this.filtrar();
                }).catch((err) => {
                  this.$error(this, err);
                });
            }
          },
        ));
    },
    exportarFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      this.requestExportacao(filtrosAplicados, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'liquidacao') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    exportarConteudo() {
      let params;

      if (!this.selecionados || this.selecionados.length <= 0) {
        params = {
          ...this.filtros,
        };

        if (params.orcamento != null) {
          params.orcamento = params.orcamento.trim();

          if (params.orcamento === '') {
            delete params.orcamento;
          }
        }
      } else {
        const ids = [];

        this.selecionados.forEach((selecionado) => {
          ids.push(selecionado.id);
        });

        params = { ids };
      }

      let quantidadeRegistros = 0;

      countRelatorioCancelamentoZver(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data.count;
          if (quantidadeRegistros > 0) {
            this.exportarFiltros(params, 'integracao-cancelamento-zver', 'job');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
};
</script>
<style>
.tabelaIntegracaoCancelamentoZver .v-data-table__wrapper table {
  width: 120%;
}
</style>
