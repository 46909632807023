<template>
  <v-form ref="form" autocomplete="off">
    <v-card style="margin-bottom:1em" class="RelatorioIntegracaoCancelamentoZver elevation-0">
      <v-card-title>
        <span class="title">{{ $tc('title.filtro', 2) }}</span>

        <v-spacer></v-spacer>
        <icon-filtro @click="toggleFiltros" :filtros="filtrosIntegracaoCancelamentoZver" />
      </v-card-title>

      <v-container fluid grid-list-md v-show="filtrosAbertos">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosIntegracaoCancelamentoZver"
          ref="container">
        </metadados-container-layout>
      </v-container>

      <v-card-actions v-show="filtrosAbertos">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
               color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
               color="primary"
        >{{$t('label.filtrar')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import MetadadosContainerLayout from '@/produto/shared-components/metadados/MetadadosContainerLayout';
import IconFiltro from '@/produto/shared-components/filtros/IconFiltro';
import RelatorioIntegracaoCancelamentoZverFiltrosCampos from './RelatorioIntegracaoCancelamentoZverFiltrosCampos';

export default {
  name: 'RelatorioIntegracaoCancelamentoZverFiltros',
  mixins: [
    RelatorioIntegracaoCancelamentoZverFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  data() {
    return {
      ordenacaoCampos: [
        'cod_pagamento',
        'purchnoc',
        'cod_acao',
        'status',
        'data_inicio',
        'data_fim',
      ],
      filtrosAbertos: true,
      filtrosFixados: false,
      filtrosIntegracaoCancelamentoZver: {},
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
    };
  },
  methods: {
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    resetaFiltros() {
      this.filtrosIntegracaoCancelamentoZver = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      if (params.orcamento != null) {
        params.orcamento = params.orcamento.trim();

        if (params.orcamento === '') {
          delete params.orcamento;
        }
      }

      this.$emit('RelatorioIntegracaoCancelamentoZver__AplicaFiltros', params);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_inicial_maior_final'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
};
</script>

<style>
.RelatorioIntegracaoCancelamentoZver__Opcoes {
  padding: 0 0 0 20px;
  margin-top: 0;
}
.RelatorioIntegracaoCancelamentoZver__Opcoes div.v-input__slot,
.RelatorioIntegracaoCancelamentoZver__Opcoes div.v-radio {
  margin-bottom: 0;
}
.RelatorioIntegracaoCancelamentoZver__Opcoes div.v-messages {
  display: none;
}
.RelatorioIntegracaoCancelamentoZver__Opcoes label {
  font-size: 14px;
}
</style>
