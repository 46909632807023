export default {
  data() {
    return {
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'cod_pagamento',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                { texto: `${this.$tc('status_integracao.PENDENTE', 1)}`, valor: 'PENDENTE_INTEGRACAO' },
                { texto: `${this.$tc('status_integracao.ERRO', 1)}`, valor: 'ERRO' },
                { texto: `${this.$tc('status_integracao.SUCESSO', 1)}`, valor: 'INTEGRADO' },
                { texto: `${this.$tc('status_integracao.PENDENTE_RETORNO', 1)}`, valor: 'PENDENTE_RETORNO' },
              ],
            },
          },
          {
            labelCampo: 'purchnoc',
            nomCampoId: 'purchnoc',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                { texto: 'ZVER_PGTO_NOM_DEPBANC', valor: 'ZVER_PGTO_NOM_DEPBANC' },
                { texto: 'ZVER_PGTO_NOM_ABATIMENTO', valor: 'ZVER_PGTO_NOM_ABATIMENTO' },
                { texto: 'ZVER_PGTO_PERC_DEPBANC', valor: 'ZVER_PGTO_PERC_DEPBANC' },
                { texto: 'ZVER_PGTO_PERC_ABATIMENTO', valor: 'ZVER_PGTO_PERC_ABATIMENTO' },
              ],
            },
          },
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
        ],
      },
    };
  },
};
