<template>
  <v-dialog
    v-model="dialog"
    width="1300"
    hide-overlay
    persistent
    transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="text-h6">
        <span>{{$t('title.rateio_pagamento')}}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{ `${$tc('label.acao', 1)} #${pagamentoIntegracao.codAcao} :: ${$tc('label.pagamento', 1)} #${pagamentoIntegracao.codPagamento} :: ${pagamentoIntegracao.tipoAcao}` }}<br/>
        {{ `${$tc('label.status_integracao', 2)}: ${$tc('status_integracao.' + pagamentoIntegracao.status, 1)} ` }}
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="itensRateio"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{ itemsPerPageOptions: [10, 25, 35] }">
        <template nowrap v-slot:item.condValue="{ item }">
          <div style="white-space: nowrap">
            {{ getMoney(item.condValue) }}
          </div>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="mx-3">
          {{ `${$tc('label.total', 1)}: ${getMoney(total)} ` }}
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getMoney } from '../../../../produto/common/functions/helpers';

export default {
  name: 'RelatorioIntegracaoCriacaoPagamentoItensTabela',
  props: {
    pagamentoIntegracao: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      resource: this.$api.relatoriosIntegracao(this.$resource),
      dialog: false,
      itensRateio: [],
      total: 0,
      headers: [
        {
          text: this.$tc('label.itm_number', 1), width: '10%', value: 'itmNumber', sortable: false,
        },
        {
          text: this.$tc('label.material_sku', 1), width: '20%', value: 'material', sortable: false,
        },
        {
          text: this.$tc('label.target_qty', 1), width: '20%', value: 'targetQty', sortable: false,
        },
        {
          text: this.$tc('label.target_qu', 1), width: '10%', value: 'targetQu', sortable: false,
        },
        {
          text: this.$tc('label.cond_value', 1), width: '20%', value: 'condValue', sortable: false,
        },
        {
          text: this.$tc('label.currency', 1), width: '10%', value: 'currency', sortable: false,
        },
        {
          text: this.$tc('label.cond_unit', 1), width: '10%', value: 'condUnit', sortable: false,
        },
      ],
    };
  },
  watch: {
    dialog(valor) {
      if (!valor) {
        this.$emit('RelatorioIntegracaoCriacaoPagamentoItensTabela__close');
      }
    },
  },
  methods: {
    getMoney,
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    somarTotal() {
      this.total = this.itensRateio
        .map((i) => i.condValue)
        .reduce((t, v) => t + v, 0);
    },
    buscarItens() {
      const params = {
        idPagamentoIntegracao: this.pagamentoIntegracao.id,
      };

      this.resource.buscarItensCriacaoPagamento(params)
        .then((response) => {
          this.itensRateio = response.data;
          this.somarTotal();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.buscarItens();
  },
};
</script>
