<template>
<div>
  <v-btn style="margin: 10px;" @click="reenviaIntegracaoEmMassa" color="primary">
    {{ $t('label.reprocessar_relatorio') }}
  </v-btn>
  <v-btn @click="exportarConteudo"
          color="primary"
          :disabled="podeExportar"
  >{{ $t('label.exportar') }}
  </v-btn>
  <v-card style="width: 100%;" class="elevation-0">
    <v-data-table
      class="tabelaIntegracaoProvisao"
      :headers="headers"
      :items="integracoes"
      v-model="selecionados"
      show-select
      :options.sync="pagination"
      :server-items-length="totalPage"
      item-key="idProvisao"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.acoes="{ item }">
        <v-tooltip bottom v-if="podeReenviarIntegracao && integracaoComErro(item)">
          <template v-slot:activator="{ on }">
            <v-btn @click.native="modalConfirmacaoReenviar(item)" v-on="on" text icon>
              <v-icon>replay</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.reenviar') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.statusProvisao="{ item }">
        <div style="white-space: nowrap">
          {{ $tc(`status_integracao.${item.statusProvisao}`, 1) }}
        </div>
      </template>
      <template nowrap v-slot:item.condValue="{ item }">
        <div style="white-space: nowrap">
          {{ getNumber(item.condValue) }}
        </div>
      </template>
      <template nowrap v-slot:item.respostaIntegracao="{ item }">
        <div style="white-space: nowrap">
          <v-tooltip left v-if="integracaoComErroOuPendente(item)">
            <template v-slot:activator="{ on: tooltip }">
              <v-btn v-on="{ ...tooltip }" icon text>
                <v-icon>info</v-icon>
              </v-btn>
            </template>
            <span>{{ item.respostaIntegracao }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>

  <confirm
    ref="confirmDialog"
    :persistent="true"
  />
  <confirm
    ref="modalConfirmacaoReenvio"
    :message="$t('message.reenviar_integracao')"
    @agree="reenviaIntegracao"
    @disagree="$refs.modalConfirmacaoReenvio.close()">
  </confirm>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { countRelatorioProvisao } from '@/common/resources/quantidade-registro-relatorio-suzano';
import exportacao from '@/produto/common/functions/exportacao';
import { getNumber, removeEmptyProperties } from '../../../../produto/common/functions/helpers';
import { buscaIntegracoesProvisao, reenviarIntegracaoProvisaoEmMassa } from '../../../../common/resources/relatorios-integracao';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  data() {
    return {
      resource: this.$api.relatoriosIntegracao(this.$resource),
      integracoes: [],
      selecionados: [],
      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      filtros: null,
      integracaoReenvio: null,
    };
  },
  components: {
    Confirm,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        if (this.filtros === null) {
          return;
        }
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    headers() {
      const headers = [];
      headers.push({
        text: '', width: '2%', value: 'acoes', sortable: false,
      });
      headers.push({
        text: this.$tc('label.status', 1), width: '8%', value: 'statusProvisao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.orcamento', 1), width: '10%', value: 'descricaoOrcamento', sortable: false,
      });
      headers.push({
        text: this.$tc('label.linha_de_investimento', 1), width: '10%', value: 'descricaoLinhaInvestimento', sortable: false,
      });
      headers.push({
        text: this.$tc('label.cod_customer', 1), width: '6%', value: 'codCustomerGroup', sortable: false,
      });
      headers.push({
        text: this.$tc('label.customer_group', 1), width: '6%', value: 'nomCustomerGroup', sortable: false,
      });
      headers.push({
        text: this.$tc('label.sequencial', 1), width: '6%', value: 'numSequencial', sortable: false,
      });
      headers.push({
        text: this.$tc('label.data_integracao', 1), width: '6%', value: 'dataRequisicao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.table_no', 1), width: '6%', value: 'tableNo', sortable: false,
      });
      headers.push({
        text: this.$tc('label.varkey', 1), width: '10%', value: 'varkey', sortable: false,
      });
      headers.push({
        text: this.$tc('label.valid_to', 1), width: '6%', value: 'validTo', sortable: false,
      });
      headers.push({
        text: this.$tc('label.valid_from', 1), width: '6%', value: 'validFrom', sortable: false,
      });
      headers.push({
        text: this.$tc('label.numreg', 1), width: '6%', value: 'numreg', sortable: false,
      });
      headers.push({
        text: this.$tc('label.aprovacao', 1), width: '6%', value: 'dataAprovacao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.cond_value', 1), width: '6%', value: 'condValue', sortable: false,
      });
      headers.push({
        text: this.$tc('label.tipo_integracao', 1), width: '10%', value: 'tipoIntegracao', sortable: false,
      });
      headers.push({
        text: this.$tc('label.erro', 2), width: '6%', value: 'respostaIntegracao', sortable: false,
      });
      return headers;
    },
    podeReenviarIntegracao() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'LOG_INT_REENVIO').length > 0;
    },
    podeExportar() {
      return ((this.integracoes == null) || (this.integracoes.length <= 0));
    },
  },
  methods: {
    getNumber,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    filtrar(filtrosAplicados = null) {
      this.selecionados = [];

      if (filtrosAplicados) {
        this.filtros = filtrosAplicados;
        this.pagination.page = 1;
      }

      const parametros = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      buscaIntegracoesProvisao(parametros, this.$resource).then((res) => {
        this.totalPage = res.data.quantidadeRegistrosPagina;
        this.integracoes = res.data.resposta;
      });
    },
    integracaoComErroOuPendente(integracao) {
      return integracao && integracao.respostaIntegracao
        && ['ERRO', 'PENDENTE_INTEGRACAO'].includes(integracao.statusIntegracao);
    },
    integracaoComErro(integracao) {
      return integracao && integracao.statusIntegracao === 'ERRO';
    },
    modalConfirmacaoReenviar(integracao) {
      this.integracaoReenvio = integracao;
      this.$refs.modalConfirmacaoReenvio.open();
    },
    reenviaIntegracao() {
      if (!this.integracaoReenvio) {
        return;
      }

      const param = {
        idProvisao: this.integracaoReenvio.idProvisao,
      };

      this.resource.reenviarIntegracaoProvisao(param, param)
        .then(() => {
          this.filtrar();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    reenviaIntegracaoEmMassa() {
      if (!this.selecionados || this.selecionados.length <= 0) {
        this.$toast(this.$tc('label.selecione_item', 2));
        return;
      }

      const content = this.$t('message.deseja_reprocessar_integracao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              if (!this.selecionados || this.selecionados.length <= 0) {
                this.$toast(this.$tc('label.selecione_item', 2));
                return;
              }

              const ids = [];

              this.selecionados.forEach((selecionado) => {
                ids.push(selecionado.idProvisao);
              });

              const parametros = { ids };

              reenviarIntegracaoProvisaoEmMassa(parametros, this.$resource, {})
                .then(() => {
                  this.integracoes = [];
                  this.selecionados = [];
                  this.filtrar();
                }).catch((err) => {
                  this.$error(this, err);
                });
            }
          },
        ));
    },
    exportarFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      this.requestExportacao(filtrosAplicados, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'liquidacao') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    exportarConteudo() {
      let params;

      if (!this.selecionados || this.selecionados.length <= 0) {
        params = {
          ...this.filtros,
        };

        if (params.orcamento != null) {
          params.orcamento = params.orcamento.trim();

          if (params.orcamento === '') {
            delete params.orcamento;
          }
        }
      } else {
        const ids = [];

        this.selecionados.forEach((selecionado) => {
          ids.push(selecionado.idProvisao);
        });

        params = { ids };
      }

      let quantidadeRegistros = 0;

      countRelatorioProvisao(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data.count;
          if (quantidadeRegistros > 0) {
            this.exportarFiltros(params, 'integracao-provisao', 'job');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
};
</script>
<style>
  .tabelaIntegracaoProvisao .v-data-table__wrapper table {
    width: 120%;
  }
</style>
