var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticStyle:{"margin":"10px"},attrs:{"color":"primary"},on:{"click":_vm.reenviaIntegracaoEmMassa}},[_vm._v(" "+_vm._s(_vm.$t('label.reprocessar_relatorio'))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.podeExportar},on:{"click":_vm.exportarConteudo}},[_vm._v(_vm._s(_vm.$t('label.exportar'))+" ")]),_c('v-card',{staticClass:"elevation-0",staticStyle:{"width":"100%"}},[_c('v-data-table',{staticClass:"tabelaIntegracaoProvisao",attrs:{"headers":_vm.headers,"items":_vm.integracoes,"show-select":"","options":_vm.pagination,"server-items-length":_vm.totalPage,"item-key":"idProvisao","no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [(_vm.podeReenviarIntegracao && _vm.integracaoComErro(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.modalConfirmacaoReenviar(item)}}},on),[_c('v-icon',[_vm._v("replay")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.reenviar')))])]):_vm._e()]}},{key:"item.statusProvisao",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$tc(("status_integracao." + (item.statusProvisao)), 1))+" ")])]}},{key:"item.condValue",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getNumber(item.condValue))+" ")])]}},{key:"item.respostaIntegracao",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[(_vm.integracaoComErroOuPendente(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.respostaIntegracao))])]):_vm._e()],1)]}}]),model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}})],1),_c('confirm',{ref:"confirmDialog",attrs:{"persistent":true}}),_c('confirm',{ref:"modalConfirmacaoReenvio",attrs:{"message":_vm.$t('message.reenviar_integracao')},on:{"agree":_vm.reenviaIntegracao,"disagree":function($event){return _vm.$refs.modalConfirmacaoReenvio.close()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }