export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'orcamento',
            nomCampoId: 'orcamento',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'tipo_integracao',
            nomCampoId: 'tipo_integracao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: 'ZAJU_CUTOFF_MES_CORRENTE', valor: 'ZAJU_CUTOFF_MES_CORRENTE' },
                  { texto: 'ZAJU_CUTOFF_MES_ANTERIOR', valor: 'ZAJU_CUTOFF_MES_ANTERIOR' },
                  { texto: 'ZAJU_AJUSTE_VERBA_PERC', valor: 'ZAJU_AJUSTE_VERBA_PERC' },
                  { texto: 'ZAJU_AJUSTE_VERBA_NOMI', valor: 'ZAJU_AJUSTE_VERBA_NOMI' },
                  { texto: 'ZAJU_AJUSTE_VERBA_CONTRATO_PERC', valor: 'ZAJU_AJUSTE_VERBA_CONTRATO_PERC' },
                  { texto: 'ZAJU_AJUSTE_VERBA_CONTRATO_NOMI', valor: 'ZAJU_AJUSTE_VERBA_CONTRATO_NOMI' },
                  { texto: 'ZAJU_AJUSTE_PGTO', valor: 'ZAJU_AJUSTE_PGTO' },
                  { texto: 'ZAJU_PGTO_REPROVADO', valor: 'ZAJU_PGTO_REPROVADO' },
                  { texto: 'ZAJU_PGTO_CANCELADO', valor: 'ZAJU_PGTO_CANCELADO' },
                  { texto: 'ZAJU_APUR_REPROVADA', valor: 'ZAJU_APUR_REPROVADA' },
                  { texto: 'ZAJU_APUR_CANCELADA', valor: 'ZAJU_APUR_CANCELADA' },
                  { texto: 'ZAJU_AJUSTE_VLR_DEVOLUCAO', valor: 'ZAJU_AJUSTE_VLR_DEVOLUCAO' },
                  { texto: 'ZAJU_APROVACAO_ACAO', valor: 'ZAJU_APROVACAO_ACAO' },
                  { texto: 'ZAJU_ESTORNO_SALDO_PERC', valor: 'ZAJU_ESTORNO_SALDO_PERC' },
                  { texto: 'ZAJU_ESTORNO_SALDO_NOMI', valor: 'ZAJU_ESTORNO_SALDO_NOMI' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_integracao.PENDENTE', 1)}`, valor: 'PENDENTE_INTEGRACAO' },
                  { texto: `${this.$tc('status_integracao.ERRO', 1)}`, valor: 'ERRO' },
                  { texto: `${this.$tc('status_integracao.SUCESSO', 1)}`, valor: 'INTEGRADO' },
                  { texto: `${this.$tc('status_integracao.PENDENTE_RETORNO', 1)}`, valor: 'PENDENTE_RETORNO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
        ],
      },
    };
  },
};
