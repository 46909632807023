<template lang="html">
  <div>
    <span class="title">{{ $tc('label.log_integracao', 1) }}</span>
    <v-card>
      <v-tabs
        v-model="tabSelecionada"
        class="relatorio-list__tabs"
        color="accent"
        centered
        background-color="rgba(0,0,0,0)">

        <v-tab class="white-text" href="#tab-relatorio-integracao-provisao">
          {{ $tc('title.provisao', 1) }}
        </v-tab>
        <v-tab class="white-text" href="#tab-relatorio-integracao-ajuste-provisao">
          {{ $tc('title.ajuste_provisao', 1) }}
        </v-tab>
        <v-tab class="white-text" href="#tab-relatorio-integracao-criacao-pagamento">
          {{ $tc('title.criacao_de_pagamento', 1) }}
        </v-tab>
        <v-tab class="white-text" href="#tab-relatorio-integracao-cancelamento-zver">
          {{ $tc('title.cancelamento_zver', 1) }}
        </v-tab>

        <v-tabs-items
          v-model="tabSelecionada">
          <v-tab-item id="tab-relatorio-integracao-provisao">
            <relatorio-integracao-provisao ref="tab-relatorio-integracao-provisao"/>
          </v-tab-item>
          <v-tab-item id="tab-relatorio-integracao-ajuste-provisao">
            <relatorio-integracao-ajuste-provisao ref="tab-relatorio-integracao-ajuste-provisao"/>
          </v-tab-item>
          <v-tab-item id="tab-relatorio-integracao-criacao-pagamento">
            <relatorio-integracao-criacao-pagamento ref="tab-relatorio-integracao-criacao-pagamento"/>
          </v-tab-item>
          <v-tab-item id="tab-relatorio-integracao-cancelamento-zver">
            <relatorio-integracao-cancelamento-zver ref="tab-relatorio-integracao-cancelamento-zver"/>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RelatorioIntegracaoCriacaoPagamento from '@/spa/relatorios-integracao/integracoes/criacao-pagamento/RelatorioIntegracaoCriacaoPagamento';
import RelatorioIntegracaoProvisao from './integracoes/provisao/RelatorioIntegracaoProvisao';
import RelatorioIntegracaoAjusteProvisao from './integracoes/ajuste-provisao/RelatorioIntegracaoAjusteProvisao';
import RelatorioIntegracaoCancelamentoZver from './integracoes/cancelamento-zver/RelatorioIntegracaoCancelamentoZver';

export default {
  data() {
    return {
      tabSelecionada: null,
    };
  },
  components: {
    RelatorioIntegracaoCriacaoPagamento,
    RelatorioIntegracaoProvisao,
    RelatorioIntegracaoAjusteProvisao,
    RelatorioIntegracaoCancelamentoZver,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
  },
  methods: {
    verificaAcesso() {
      const ok = this.getAllRoles.filter((rel) => rel.startsWith('LOG_INT_').length > 0);
      if (!ok) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    this.verificaAcesso();
  },
};
</script>

<style lang="scss">
.relatorio-list__tabs a.v-tab {
  color: var(--v-accent) !important;
}
.relatorio-list__tabs .v-tabs-items {
  background: none;
}
.relatorio-list__tabs div.v-slide-group__wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

</style>
