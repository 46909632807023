export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'orcamento',
            nomCampoId: 'orcamento',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_integracao.PENDENTE', 1)}`, valor: 'PENDENTE_INTEGRACAO' },
                  { texto: `${this.$tc('status_integracao.ERRO', 1)}`, valor: 'ERRO' },
                  { texto: `${this.$tc('status_integracao.SUCESSO', 1)}`, valor: 'INTEGRADO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
        ],
      },
    };
  },
};
