1<template>
  <div>
    <relatorio-integracao-ajuste-provisao-filtros
      ref="filtros"
      v-model="abrirFiltro"
      @RelatorioIntegracaoAjusteProvisao__AplicaFiltros="aplicarFiltros"
      @RelatorioIntegracaoAjusteProvisao__ExportarFiltros="exportarFiltros"/>

    <relatorio-integracao-ajuste-provisao-tabela
      ref="tabela"/>
  </div>
</template>
<script>
import { removeEmptyProperties } from '@/produto/common/functions/helpers';
import exportacao from '@/produto/common/functions/exportacao';
import RelatorioIntegracaoAjusteProvisaoFiltros from './RelatorioIntegracaoAjusteProvisaoFiltros';
import RelatorioIntegracaoAjusteProvisaoTabela from './RelatorioIntegracaoAjusteProvisaoTabela';

export default {
  components: {
    RelatorioIntegracaoAjusteProvisaoFiltros,
    RelatorioIntegracaoAjusteProvisaoTabela,
  },
  computed: {
  },
  data() {
    return {
      abrirFiltro: false,
      filtros: Object,
    };
  },
  methods: {
    aplicarFiltros(filtros) {
      this.$refs.tabela.filtrar(filtros);
      this.filtros = filtros;
    },
    exportarFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      this.filtros = {
        ...filtrosAplicados,
      };
      this.requestExportacao(this.filtros, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'liquidacao') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
  },
};
</script>
